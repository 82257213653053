// import { addGameCard, cardGameNewCards, cardGameWinner } from './cardGameUtil'
// import { startGame } from './cardGameAPI'
// import { syncGame } from './cardGameTick'
// import { loadMyEnergy } from '../energy/energyUtil'

import { getSessionId } from '../utils'
import { loadMyEnergy } from '../utils/energy/energyUtil'
import { updateStreamsAnt } from '../modules/socket/actions'
import { isLocalHost } from '../utils/urlUtils'
import { closeCurrentPanel, createPanelUniversal } from '../components/Panels/createPanelUtil'
import { CONNECTION_LOST } from '../components/Panels/types'
import config from '@/config'
const { ftCoreWSSUrl } = config
let instance

const LOCAL = true
export function createCoreAPIWSS() {
  if (instance) {
    instance.close()
  }
  authSession = null
  instance = null
  instance = new WebSocket((isLocalHost() && LOCAL) ? 'ws://localhost:8000/ws' : ftCoreWSSUrl)
  instance.onopen = () => {
    authCoreSocket()
    //TODO временно отключим
    // closeCurrentPanel()
  }
  instance.onmessage = ({ data }) => onmessage({ data })
  instance.onclose = ({ wasClean, code, reason }) => onclose({ wasClean, code, reason })
  instance.onerror = ({ message }) => {
    console.log(message)
  }
}

async function onmessage(data) {
  let json
  try {
    json = await JSON.parse(data.data)
  } catch (e) {
    json = data
  }
  const { method, params } = json
  console.log(data)

  switch (method) {
    case 'streamsUpdateAnt':
      //TODO временно отключим
      // updateStreamsAnt(params.streams)
      return
    // case 'cardGameWinner':
    //   return cardGameWinner(params)
    // case 'cardGameTick':
    //   return syncGame(params)
    // case 'updateRoomCards':
    //   return syncGame(params, true)
    // //добавление карточек пачкой
    // case 'cardGameNewCards':
    //   return cardGameNewCards(params.new_cards)
    case 'quizFinished':
      return
    // case 'cardGameStart':
    //   return startGame(params)
  }
}

const MAX_RECONNECT_DELAY = 5000
function onclose(data) {
  setTimeout(() => {
    createCoreAPIWSS()
  }, Math.random() * MAX_RECONNECT_DELAY)
  //TODO временно отключим
  // createPanelUniversal(CONNECTION_LOST)
}

export function sendCorApiWsMessage(method, params, errorCb) {
  const data = JSON.stringify(params ? { method, params } : { method })
  try {
    if (instance && instance.readyState !== undefined && instance.readyState === instance.OPEN && instance.readyState !== instance.CONNECTING) {
      instance.send(data)
    } else {
      console.log('!!! game wss error ' + data)
      if (errorCb) {
        errorCb()
      }
    }
  } catch (e) {
    console.error(data)
    if (errorCb) {
      errorCb()
    }
  }
}

let authSession

export function authCoreSocket() {
  if (!getSessionId()) {
    return
  }
  if (getSessionId() === authSession) {
    return
  }
  // // loadMyEnergy()
  authSession = getSessionId()
  sendCorApiWsMessage('authorize', { sid: getSessionId() }, () => {
    authSession = null
    setTimeout(() => {
      authCoreSocket()
    }, 1000)
  })
}
