import React, { Component } from 'react'
import { array, bool, func, number, string } from 'prop-types'
import { connect } from '@cerebral/react'
import { sequences, state } from 'cerebral'
import { AppLoading } from '../components/Loading'
import is from 'is_js'
import { createWsConnection, isDesktopSkin, isMobileSkin, isPlaceHolder, } from '../utils'
import { initOneSignal } from '@/utils/OneSignal'
import { isPartnerDomain } from '@/utils/urlUtils'
import { messageTypes } from '../modules/app/signals/onIframeMessage'
import LocalStorageUtil from '@/utils/LocalStorageUtil'
import { setMyLSProps, updateLSProps } from '../utils/LocalStorageUtil'
import { getAppId, isMSPWA } from '../utils/twa'
import { MS_PWA } from '../utils/api/PWAUtil'
import { createGameWSS } from '../utils/cardGame/cardGameWSS'
import { createCoreAPIWSS } from '../ws/coreAPIWSS'
import { getBaseData } from '../api/getBaseData'

if (is.desktop() && !isPartnerDomain()) {
  const html = document.getElementsByTagName('html')[0]
  const body = document.getElementsByTagName('body')[0]
  const app = document.getElementById('app')
  html.classList.add('minSizeForOurDomains')
  body.classList.add('minSizeForOurDomains')
  app.classList.add('minSizeForOurDomains')
}

document.getElementById('app').classList.add('disable-selection')

export default connect({
    appIsReady: state`app.isReady`,
    appBan: state`app.ban`,
    panels: state`app.panels`,
    onIframeMessage: sequences`app.onIframeMessage`,
    panelsOnPop: sequences`app.panelsOnPop`,
    popRoutesHistory: sequences`app.popRoutesHistory`,
    setNotificationsButtonMode: sequences`app.setNotificationsButtonMode`,
    onmessage: sequences`socket.onmessage`,
    onclose: sequences`socket.onclose`,
    onopen: sequences`socket.onopen`,
    loadState: sequences`app.loadState`,
    processingOfRoutes: sequences`app.processingOfRoutes`,
    onerror: sequences`socket.onerror`,
    getConfig: sequences`shop.getConfig`,
    getLocale: sequences`intl.getLocale`,
    direction: state`intl.direction`,
    windowH: state`useragent.window.height`
  },
  class AppLoader extends Component {
    static propTypes = {
      appBan: string,
      appIsReady: bool,
      getConfig: func,
      getLocale: func,
      loadState: func,
      onIframeMessage: func,
      onclose: func,
      onerror: func,
      onmessage: func,
      onopen: func,
      panels: array,
      panelsOnPop: func,
      popRoutesHistory: func,
      processingOfRoutes: func,
      setNotificationsButtonMode: func,
      viewsIndex: number,
    }

    state = {
      skinType: null,
      device: null,
      isLoading: false
    }

    onPopState = e => {
      e.preventDefault()
      let waitTime = 20
      if (this.props.panels.length) {
        this.props.panelsOnPop({ hideAll: true })
        waitTime = 300
      }
      setTimeout(() => this.props.popRoutesHistory(), waitTime)
    }

    componentDidMount() {
      const { onopen, onmessage, onclose, onerror, onIframeMessage, loadState, processingOfRoutes } = this.props

      window.addEventListener('beforeunload', () => {
        if (isMSPWA()) {
          setMyLSProps(MS_PWA, Date.now())
          setMyLSProps('pwaAppId', getAppId())
        }
      })
      window.addEventListener('popstate', this.onPopState)
      window.addEventListener('message', e => {
        if (e.data && typeof e.data === 'string') {
          let parsedData
          try {
            parsedData = JSON.parse(e.data)
          } catch (error) {
            parsedData = e.data
          }
          if (parsedData.type && messageTypes[parsedData.type]) {
            onIframeMessage({ data: parsedData })
          }
        }
      })

      if (!isPlaceHolder()) {
        console.log('apploader connection')
        getBaseData()
        createGameWSS()
        createCoreAPIWSS()
        createWsConnection({ onopen, onmessage, onclose, onerror })
      }

      initOneSignal()
      this.loadDevice()
      loadState()
      processingOfRoutes()
    }

    componentDidUpdate() {
      if (this.getSkinType() !== this.state.skinType) {
        this.loadDevice()
      }
    }

    componentWillUnmount() {
      window.removeEventListener('popstate', this.onPopState)
    }

    getSkinType = () => {
      if (isPlaceHolder()) {
        return 'placeholder'
      } else if (isDesktopSkin()) {
        return 'desktop'
      } else if (isMobileSkin()) {
        return 'mobile'
      } else {
        return 'placeholder'
      }
    }

    loadDevice = async () => {
      if (this.state.isLoading) {
        return
      }
      this.setState({ device: null, skinType: null, isLoading: true })
      const skinType = this.getSkinType()
      let Device = null

      try {
        if (skinType === 'desktop' || skinType === 'mobile') {
          Device = await import('./Screens')
        } else {
          Device = await import('./Placeholder')
        }
        this.setState({ skinType, device: Device.default, isLoading: false })
      } catch (e) {
        console.warn(e)
      }
      window.endLoadingTime = Date.now()
    }

    render() {
      const Device = this.state.device

      if (!Device || !this.props.appIsReady) {
        return <AppLoading/>
      }

      window.loadSuccess = true
      return (
        [<Device key="device" skin={this.getSkinType()}/>, <LocalStorageUtil key="syncer"/>]
      )
    }
  })
