import { GETRequest } from '../utils/api/requestWrapper'
import { getCoreUrl } from '../utils/api/apiURLUtil'
import { updateStreamsAnt } from '../modules/socket/actions'

export function getBaseData () {
    GETRequest(getCoreUrl('/api/v1/on_connect_data', true), (data)=>{
        // updateCoins(data.result)
        console.log(data.streams)
        //TODO временно отключим
        // updateStreamsAnt(data.streams)
    }, (data)=>{
        console.log(data)
    }, true)
}
