import { when } from 'cerebral/factories'
import { onconnect } from '../actions'
import { state } from 'cerebral'
import destroyPanel from '../../app/signals/destroyPanel'
import getAditionalResources from '../../app/chains/getAditionalResources'
import sendGoInRoom from '../../room/signals/sendGoInRoom'
import { setDefaultFilters } from '../../../utils/filtersUtil'
import { callSequence, setStoreWrapper } from '../../../utils/StoreManager'
import { selectFeed } from '../../filters/actions/feedUtil'
import { TYPE_CYBER, TYPE_LIVE_ONLY, TYPE_REAL_DATING } from '../../posts/signals/getPosts'
import { resetWSSReconnectTimeout } from '../../../utils/api/timeoutUtil'
import { isAIDatingApp, isDatingApp } from '../../../utils/appConfigUtil'

export default [
  ({ props, store, get }) => {
    console.log('wssOnConnect')
    resetWSSReconnectTimeout()
    if (!props.params.appSourceConfig) {
      store.set('posts.filters', setDefaultFilters([]))
      if (isDatingApp()) {
        selectFeed(TYPE_REAL_DATING)
      }
      return
    }
    let appSourceConfig = props.params.appSourceConfig

    store.set('intl.appSourceConfig', appSourceConfig)
    if (appSourceConfig.id) {
      console.log('subject:', appSourceConfig.id)
      store.set('app.subject', appSourceConfig.id)
    }

    const priorityTags = get(state`intl.appSourceConfig.tags`)
    if (priorityTags) {
      store.set('posts.priorityTags', [...priorityTags])
    }

    store.set('posts.filters', setDefaultFilters(appSourceConfig.filters))
    const feed = isAIDatingApp() ? TYPE_CYBER : (isDatingApp() ? TYPE_REAL_DATING : TYPE_LIVE_ONLY)
    selectFeed(feed)

    if (appSourceConfig.tagsIdExcluded) {
      store.set('posts.tagsIdExcluded', appSourceConfig.tagsIdExcluded)
    }

    callSequence('intl.getLocale')

    // console.log('countryFullName: ', props.params.countryFullName)
    // setStoreWrapper('countryFullName', props.params.countryFullName || 'United States')
  },
  when(
    state`app.panels`,
    panels => panels.length > 0 && panels[panels.length - 1].type === 'connectionLostPanel'
  ), {
    true: [destroyPanel],
    false: []
  },
  onconnect, {
    inOtherRoute: [
      getAditionalResources,
    ],
    inRoom: [
      getAditionalResources,
      when(state`posters.raw.${state`app.roomId`}`), {
        false: [],
        true: [sendGoInRoom]
      }
    ]
  }
]
