import { messagesRoute } from '@/utils/routerHelpers'
import {
    ALL_SHOWCASE,
    allowNsfwPanel,
    askPrivatePanel,
    AUTO_MESSAGES_PANEL,
    AWARD_VIEW_PANEL,
    awardsPanel,
    buffPanel,
    BUY_VIP,
    callPanel,
    CARD_GAME_INFO_PANEL,
    DATING_INSTRUCTIONS_PANEL,
    dialogMediaBuyConfirm,
    donatePanel,
    EMAIL_PANEL,
    ENERGY_PANEL,
    firstIncomePanel,
    GAME_CARDS_PANEL,
    genderSelectPanel,
    giftSetsPanel,
    loginPanel,
    lvlPanel,
    POST_CREATION_PANEL,
    postEditPanel,
    postOptionsPanel,
    PROFILE_CREATION_PANEL,
    quizPanel,
    RATING_PANEL,
    RATING_VIEW,
    ratingPanel,
    selectPostTagsPanel,
    setLocationPanel,
    shareAndEarnPanel,
    showStartPanel,
    subRequirementsPanel,
    subscriptionPanel,
    topPanel,
    uploadDialogMedia,
    userPanel,
    USERS_LIST,
    VOICE_SELECT_PANEL,
    VOICING_PANEL
} from './types'
import { callSequence, getSequence, getState, setStoreWrapper } from '../../utils/StoreManager'
import { getMyProfile, getMyUid } from '../../utils/my'
import { closeFullscreenPlayer } from '../Media/fullscreenPlayerUtil'
import { sendAmplitudeEvent } from '../../utils/GAEvent'
import { isMe } from '../../utils'
import { getGameCardFromBuff } from '../../utils/cardGame/cardGameUtil'
import { isSpecialPanelBuff } from '../../utils/buffUtils'
import { getMyLSProp, setMyLSProps } from '../../utils/LocalStorageUtil'
import { getGiftStars } from '../../utils/giftUtil'
import { getProfilePostId } from '../../utils/userUtil'
import { getPostData, getPostModerationStatus } from '../../utils/postUtils'
import { clearPostCreatedData } from '../../utils/post/profileUtil'
import { setInCollector } from '../../zustand/useMyStore'

// import { RATING_ADD } from './RatingPanel'

export const VIDEO_CHAT_POST = 'videochat_photo'

export function createLVLPanel(uid) {
    getSequence('app.createPanel')(lvlPanel(uid))
}

export function createTopPanel(topId, history) {
    getSequence('app.createPanel')(topPanel(topId, history))
}

export function createUserPanel(uid, showReply) {
    getSequence('app.createPanel')(userPanel(uid, showReply))
}

export function createDialogPanel(uid, aiMode) {
    setInCollector('aiMode', aiMode)
    callSequence('app.prepareAndRouteTo', { url: messagesRoute, urlValue: uid })
}

export function createAskPrivatePanel(uid) {
    setStoreWrapper('toUid', uid)
    sendAmplitudeEvent('call_panel')
    getSequence('app.createPanel')(askPrivatePanel)
}

export function createDialogMediaUpload(uid) {
    getSequence('app.createPanel')(uploadDialogMedia(uid))
}

export function createDialogMediaBuyConfirm(dialogId, postId, cost, previewUrl, mediaTypeVideo) {
    getSequence('app.createPanel')(dialogMediaBuyConfirm(dialogId, postId, cost, previewUrl, mediaTypeVideo))
}

export function createDonatePanel(donationType, donationLink, uid) {
    getSequence('app.createPanel')(donatePanel(donationType, donationLink, uid))
}

export function createLoginPanel() {
    getSequence('app.createPanel')(loginPanel)
}

export function createGiftSetsPanel(uid) {
    getSequence('app.createPanel')(giftSetsPanel(uid))
}

export function createShareAndEarnPanel() {
    getSequence('app.createPanel')(shareAndEarnPanel)
}

export function createPostEditPanel(postId = null) {
    getSequence('app.createPanel')(postEditPanel(postId))
}

export function createPostOptionsPanel(postId) {
    getSequence('app.createPanel')(postOptionsPanel(postId))
}

export function createSubRequirementsPanel(uid) {
    getSequence('app.createPanel')(subRequirementsPanel(uid))
}

export function createSubscriptionPanelPanel(uid) {
    getSequence('app.createPanel')(subscriptionPanel(uid))
}

export function createCallPanel(uid, incoming, themeId) {
    getSequence('app.createPanel')(callPanel(uid, incoming, themeId))
}

export function createAwardsPanel(group, slot, setInSlotMode, uid) {
    sendAmplitudeEvent('awards', { action: 'panel_open', myPanel: isMe(uid) })
    getSequence('app.createPanel')(awardsPanel(group, slot, setInSlotMode, uid))
}

export function createMagicViewPanel(uid, awardId, cost, buffId, energy) {
    getSequence('app.createPanel')({
        options: {
            uid,
            type: AWARD_VIEW_PANEL,
            flexMinHeight: true,
            gameCard: true,
            awardId,
            cost,
            buffId,
            energy
        }
    })
    // getSequence('app.createPanel')(awardViewPanel(awardId, uid, slot, strength, cost))
}

export function createAwardViewPanel(awardId, uid, slot, strength, cost) {
    getSequence('app.createPanel')({
        options: {
            type: AWARD_VIEW_PANEL,
            flexMinHeight: true,
            awardId,
            uid,
            slot,
            strength,
            cost
        }
    })
    // getSequence('app.createPanel')(awardViewPanel(awardId, uid, slot, strength, cost))
}

export function createGetVipPanel(reason) {
    closeFullscreenPlayer()
    sendAmplitudeEvent('get_vip_panel', { reason })
    getSequence('app.createPanel')({ options: { type: BUY_VIP, reason, flexMinHeight: true } })
}

export function createSetPasswordPanel(panelType) {
    createPanelUniversal(EMAIL_PANEL, { panelType })
    // getSequence('app.createPanel')({ options: { type: 'changeEmail', form: 'changeEmailFirst', flexMinHeight: true } })
    // getSequence('app.createPanel')(changeEmailPanel)
}

export function createAllowNsfwPanel() {
    getSequence('app.createPanel')(allowNsfwPanel('photo'))
}

export function createAddRatingPanel(uid) {
    getSequence('app.createPanel')(ratingPanel(uid, 'rating_add'))
}

export function createRatingViewPanel(uid, ratingData) {
    setStoreWrapper('ratingData', ratingData)
    getSequence('app.createPanel')(ratingPanel(uid, RATING_VIEW))
}

export function createGiftSendInfoPanel(toUid, giftId, subscribe) {
    const ratingData = {
        rating: getGiftStars(giftId),
        giftId,
        toUid,
        fromUid: getMyUid()
    }
    setStoreWrapper('ratingData', ratingData)
    callSequence('app.createPanel', {
        options: {
            type: RATING_PANEL,
            uid: toUid,
            subscribe,
            ratingType: RATING_VIEW,
            sendGiftMode: true
        }
    })
}

export function createAllShowcasePanel(uid) {
    callSequence('app.createPanel', { options: { type: ALL_SHOWCASE, uid } })
}

export function createSetLocationPanel(type) {
    getSequence('app.createPanel')(setLocationPanel(type))
}

export function createBuffPanel(buffId, fromUid) {
    getSequence('app.createPanel')(buffPanel(buffId, getMyUid(), fromUid))
}

export function closeCurrentPanel() {
    callSequence('app.panelsOnPop')
}

export function closeAllPanels() {
    closeFullscreenPlayer()
    callSequence('app.panelsOnPop', { hideAll: true })
}

export function createSelectPostTagsPanel(categoryId) {
    getSequence('app.createPanel')(selectPostTagsPanel(categoryId))
}

export function createVoiceSelectPanel() {
    getSequence('app.createPanel')({
        options: {
            type: VOICE_SELECT_PANEL
        }
    })
}

export function createShowStartPanel() {
    getSequence('app.createPanel')(showStartPanel)
}

export function createFirstIncomePanel() {
    getSequence('app.createPanel')(firstIncomePanel())
}

export function createGenderSelectPanel() {
    getSequence('app.createPanel')(genderSelectPanel())
}

export function createQuizPanel() {
    getSequence('app.createPanel')(quizPanel())
}

export function createGameCardsPanel() {
    getSequence('app.createPanel')({ options: { type: GAME_CARDS_PANEL, flexMinHeight: true } })
}

export function createAddBuffPanel(uid) {
    getSequence('app.createPanel')({
        options: {
            type: GAME_CARDS_PANEL,
            toUid: uid,
            buffMode: true,
            flexMinHeight: true
        }
    })
}

export function createPanelUniversal(type, params = {}, flexMinHeight = true) {
    getSequence('app.createPanel')({
        options: {
            type,
            flexMinHeight: flexMinHeight,
            ...params
        }
    })
}

export function createCardGameInfoPanel(boss) {
    if (!boss) {
        boss = getState('gameBoss')
    }
    getSequence('app.createPanel')({ options: { type: CARD_GAME_INFO_PANEL, flexMinHeight: true, boss } })
}

export function createBuffViewPanel(buffId, toUid, fromUid) {
    if (isSpecialPanelBuff(buffId) && getGameCardFromBuff(buffId)) {
        getSequence('app.createPanel')({
            options: {
                type: AWARD_VIEW_PANEL,
                flexMinHeight: true,
                awardId: getGameCardFromBuff(buffId),
                slot: -1,
                uid: toUid,
                fromUid,
                buffId
            }
        })

        return
    }
    getSequence('app.createPanel')(buffPanel(buffId, toUid, fromUid))
}

export function createEnergyPanel(openReason) {
    getSequence('app.createPanel')({ options: { type: ENERGY_PANEL, openReason } })
}

export function createUserListPanel(type) {
    getSequence('app.createPanel')({ options: { type: USERS_LIST, id: type } })
}

export function createAutoMessagesPanel() {
    getSequence('app.createPanel')({ options: { type: AUTO_MESSAGES_PANEL } })
}

export function createVoicingPanel() {
    getSequence('app.createPanel')({ options: { type: VOICING_PANEL } })
}

export function createPostCreationPanel(postId) {
    clearPostCreatedData()
    setStoreWrapper('upload.postKind', VIDEO_CHAT_POST)

    if (postId) {
        setStoreWrapper('editedPost', postId)
    }

    const pd = getPostData(postId)
    if (pd) {
        setStoreWrapper('upload.title', pd.originalTitle)
        setStoreWrapper('upload.selectedFileUrl', pd.original)
        setStoreWrapper('originalMainPicUrl', pd.original)
        setStoreWrapper('upload.description', pd.originalDesc)
        if (getPostModerationStatus(postId)) {
            setStoreWrapper('goodAvatar', false)
        } else {
            setStoreWrapper('goodAvatar', true)
        }
        if (pd.tags && pd.tags.length) {
            setStoreWrapper('upload.selectedTags', pd.tags)
        }
    }

    callSequence('app.createPanel', { options: { type: POST_CREATION_PANEL } })

    // setStoreWrapper('posts.selectedPostId', myProfilePostId)
    // if (myProfilePostId && myProfilePostId.mediaFilesIds && myProfilePostId.mediaFilesIds.main_file_id) {
    //   setStoreWrapper('upload.mainFileId', myProfilePostId.mediaFilesIds.main_file_id)
    // }
}

export function createProfileCreationPanel(forAccessBlock) {
    clearPostCreatedData()
    const myProfilePostId = getMyProfile()
    setStoreWrapper('upload.postKind', 'postProfile')

    const myPd = getPostData(getProfilePostId(getMyUid()))
    if (myPd) {
        setStoreWrapper('upload.selectedFileUrl', myPd.original)
        setStoreWrapper('originalMainPicUrl', myPd.original)
        if (getPostModerationStatus(myProfilePostId)) {
            setStoreWrapper('goodAvatar', false)
        } else {
            setStoreWrapper('goodAvatar', true)
        }
        setStoreWrapper('upload.description', myPd.originalDesc)

        if (myPd.tags && myPd.tags.length) {
            setStoreWrapper('upload.selectedTags', myPd.tags)
        }
    }

    callSequence('app.createPanel', { options: { type: PROFILE_CREATION_PANEL, forAccessBlock } })

    setStoreWrapper('posts.selectedPostId', myProfilePostId)
    if (myProfilePostId && myProfilePostId.mediaFilesIds && myProfilePostId.mediaFilesIds.main_file_id) {
        setStoreWrapper('upload.mainFileId', myProfilePostId.mediaFilesIds.main_file_id)
    }
}

export function createDatingInstructionsPanel() {
    if (getMyLSProp('datingInstruction')) {
        return
    }
    getSequence('app.createPanel')({ options: { type: DATING_INSTRUCTIONS_PANEL } })
    setMyLSProps('datingInstruction', true)
}
